<template>
  <div>
    <div class="globle_border">
      <div class="search-box">
        <div class="input-box types">
          <el-cascader :options="typeOptions" placeholder="筛选分类" v-model="categoryIds" @change="changetype"
            :props="cascaderprops" collapse-tags clearable></el-cascader>
        </div>
        <!-- <div class="input-box">
          <el-select v-model="tendersStatus" placeholder="选择招标状态" @change="onScreenData" clearable>
            <el-option v-for="item in tendersOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div> -->
        <div class="input-box">
          <el-input v-model="codeValue" placeholder="搜索商品条形码" @change="onScreenData" clearable></el-input>
        </div>
        <div class="input-box">
          <el-input v-model="serialId" @change="onScreenData" placeholder="搜索商品编号" clearable></el-input>
        </div>
        <div class="input-box">
          <el-input v-model="productName" @change="onScreenData" placeholder="搜索商品名称" clearable></el-input>
        </div>
        <!-- <el-button type="primary" plain @click="downloadTemplate">下载模板</el-button>
        <el-button type="primary" @click="importShow = true">导入</el-button>
        <el-button type="primary" @click="handleAdd">新增</el-button> -->
      </div>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" v-loading="dataLoding">
          <el-table-column prop="number" label="序号" width="60" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column label="图片" width="100" align="center">
            <template slot-scope="scope">
              <el-image class="busilicense" style="width: 80px; height: 58px"
                :src="scope.row.picUrlList && scope.row.picUrlList.length > 0 ? scope.row.picUrlList[0] : ''"
                fit="cover" @mouseover="previewImage(scope.row.picUrlList)"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="serialId" label="商品编号" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productName" label="商品名称" min-width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :title="scope.row.productName">{{ scope.row.productName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="categoryName" label="分类名称" min-width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :title="scope.row.categoryName">{{ scope.row.categoryName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="specs" label="规格" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="enterBox" label="入箱数"></el-table-column>
          <el-table-column prop="brand" label="品牌" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="factoryName" label="厂家" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="barCode" label="商品条形码" min-width="160" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column label="中标有效期" min-width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.startDate }} - {{ scope.row.endDate }}
            </template>
          </el-table-column> -->
          <el-table-column prop="validityPeriod" label="保质期(天)" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="costPrice" label="供货价(元)" min-width="120" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column min-width="100" show-overflow-tooltip prop="tendersStatus" label="招标状态">
            <template slot-scope="scope">
              <span>
                {{ scope.row.tendersStatus == "0" ? "未招标" : scope.row.tendersStatus == "1" ? "已招标" : "-" }}
              </span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column show-overflow-tooltip prop="firstWarnDay" label="第一次预警">
            <template slot-scope="scope">
              <div v-if="scope.row.firstWarnDay && scope.row.firstWarnPercent">
                {{ `${scope.row.firstWarnDay}天(${scope.row.firstWarnPercent}%)` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="secondWarnDay" label="第二次预警(天)" show-overflow-tooltip width="115"></el-table-column> -->
          <el-table-column label="操作" header-align="center" align="center" width="180" fixed="right">
            <template slot-scope="scope">
              <!-- <el-button class="btn" type="" plain @click="handleEdit(scope.row)">修改</el-button> -->
              <!-- <el-button class="btn" type="primary" plain @click="handleOpenPicDialog(scope.row)">编辑</el-button> -->
              <el-button class="btn" type="primary" plain @click="goToEdit(scope.row)">编辑</el-button>
              <el-button class="btn" type="danger" plain @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <!-- 编辑 -->
    <el-dialog :title="isAdd ? '新增' : '修改'" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" ref="ruleForm" label-width="150px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="productName">
              <el-input v-model="ruleForm.productName" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="规格" prop="specsValue">
              <el-input v-model="ruleForm.specsValue" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="品牌" prop="brand">
              <el-input v-model="ruleForm.brand" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="入箱数" prop="enterBox">
              <el-input v-model="ruleForm.enterBox" placeholder="请输入" type="number"></el-input>
            </el-form-item>
            <el-form-item class="day" label="保质期" prop="validityPeriod">
              <el-input v-model="ruleForm.validityPeriod" placeholder="请输入" type="number">
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类名称" prop="categoryId">
              <el-cascader v-model="ruleForm.categoryId" :options="typeOptions" :show-all-levels="false"
                :props="{ checkStrictly: true, label: 'categoryName', value: 'categoryId', emitPath: false }"></el-cascader>
            </el-form-item>
            <el-form-item label="单位" prop="specsUnit">
              <el-select v-model="ruleForm.specsUnit" placeholder="请选择">
                <el-option v-for="item in unitOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="厂家" prop="factoryName">
              <el-input v-model="ruleForm.factoryName" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="商品条形码" prop="barCode">
              <el-input v-model="ruleForm.barCode" placeholder="请输入" @input="handleInputNumAndEng"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入" :visible.sync="importShow" :before-close="onImportCancel">
      <el-form label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="上传附件" required>
              <el-upload :action="apiBaseUrl" :headers="apiHeaders" :file-list="importFiles" accept=".xlsx,.xls"
                :on-change="beforeUpload" :auto-upload="false" :limit="1" :on-exceed="handleExceed"
                :on-remove="handleRemove">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" style="color: red">提示：只能上传"xlsx"、"xls"文件，且不超过5MB</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onImportCancel">关 闭</el-button>
        <el-button type="primary" @click="submitFormImport('importRuleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑图片 -->
    <el-dialog title="编辑" :visible.sync="picDialogVisible" :before-close="onCancelPicDialog">
      <el-form :model="picRuleForm" :rules="picRules" ref="picRuleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="productName">
              <el-input v-model="picRuleForm.productName" placeholder="自动带入" readonly></el-input>
            </el-form-item>

            <el-form-item class="item logo_pic" label="商品图片" prop="totalPic">
              <div class="pic-row">
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                    :show-file-list="false" :on-success="handleSuccess1" :before-upload="beforeUploads"
                    :on-remove="handleRemoveLogo1" :disabled="picRuleForm.picUrl1 ? true : false">
                    <img v-if="picRuleForm.picUrl1" :src="picRuleForm.picUrl1" class="logoimg" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="picRuleForm.picUrl1" class="delpic delpic1 el-icon-error" @click="handleRemoveLogo1"></i>
                </div>
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                    :show-file-list="false" :on-success="handleSuccess2" :before-upload="beforeUploads"
                    :on-remove="handleRemoveLogo2" :disabled="picRuleForm.picUrl2 ? true : false">
                    <img v-if="picRuleForm.picUrl2" :src="picRuleForm.picUrl2" class="logoimg" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="picRuleForm.picUrl2" class="delpic delpic2 el-icon-error" @click="handleRemoveLogo2"></i>
                </div>
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                    :show-file-list="false" :on-success="handleSuccess3" :before-upload="beforeUploads"
                    :on-remove="handleRemoveLogo3" :disabled="picRuleForm.picUrl3 ? true : false">
                    <img v-if="picRuleForm.picUrl3" :src="picRuleForm.picUrl3" class="logoimg" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="picRuleForm.picUrl3" class="delpic delpic3 el-icon-error" @click="handleRemoveLogo3"></i>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancelPicDialog">取 消</el-button>
        <el-button type="primary" @click="submitFormPic('picRuleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :on-close="() => {
            showViewer = false
          }
            " :url-list="imgList"></el-image-viewer>
  </div>
</template>

<script>
export default {
  name: "productList",
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      dataLoding: false,
      serialId: "",
      productName: "",
      codeValue: "", // 商品条形码
      // tendersStatus: "", // 招标状态
      tableData: [],
      tendersOptions: [
        { label: "未招标", value: "0" },
        { label: "已招标", value: "1" },
      ],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      // 弹窗
      isAdd: false,
      dialogVisible: false,
      typeOptions: [],
      // 单位选择
      unitOptions: [
        {
          value: "g",
          label: "g",
        },
        {
          value: "kg",
          label: "kg",
        },
        {
          value: "ml",
          label: "ml",
        },
        {
          value: "l",
          label: "l",
        },
        {
          value: "包",
          label: "包",
        },
        {
          value: "件",
          label: "件",
        },
        {
          value: "双",
          label: "双",
        },
        {
          value: "盒",
          label: "盒",
        },
      ],
      ruleForm: {
        productName: "",
        specsValue: "",
        categoryId: "",
        barCode: "",
        enterBox: "",
        validityPeriod: "",
        specsUnit: "",
      },
      rules: {
        productName: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "change",
          },
        ],
        specsValue: [
          {
            required: true,
            message: "请输入规格",
            trigger: "change",
          },
        ],
        specsUnit: [
          {
            required: true,
            message: "请选择单位",
            trigger: "blur",
          },
        ],
        categoryId: [
          {
            required: true,
            message: "请选择分类",
            trigger: "change",
          },
        ],
        brand: [
          {
            required: true,
            message: "请输入品牌",
            trigger: "change",
          },
        ],
        factoryName: [
          {
            required: true,
            message: "请输入厂家",
            trigger: "change",
          },
        ],
        barCode: [
          {
            required: true,
            message: "请输入商品条形码",
            trigger: "change",
          },
        ],
        enterBox: [
          {
            required: true,
            message: "请输入入箱数",
            trigger: "change",
          },
        ],
        validityPeriod: [
          {
            required: true,
            message: "请输入保质期",
            trigger: "change",
          },
        ],
      },
      submitLoding: false,
      // 导入弹窗数据
      apiBaseUrl: this.$axios.defaults.baseURL + this.$api.importNewProductApply,
      apiHeaders: {
        token: this.$store.state.token,
      },
      importShow: false,
      importFiles: [], //文件列表

      // 编辑图片
      picDialogVisible: false,
      picRuleForm: {
        productName: "",
        productId: "",
        totalPic: "",
        picUrl1: "",
        picUrl2: "",
        picUrl3: "",
      },
      picRules: {
        totalPic: [
          {
            validator: () => {
              if (!this.picRuleForm.picUrl1 && !this.picRuleForm.picUrl2 && !this.picRuleForm.picUrl3) {
                return Promise.reject("请至少上传一张图片")
              } else {
                return Promise.resolve()
              }
            },
            trigger: "submit",
          },
        ],
      },

      // 图片预览
      showViewer: false,
      imgList: [],

      action: this.$axios.defaults.baseURL + this.$api.uploadFile,

      categoryId: "",
      categoryIds: [],
      cascaderprops: {
        checkStrictly: false,
        emitPath: false,
        multiple: true,
        value: "categoryId",
        label: "categoryName",
        children: "children",
      },
    }
  },
  created() {
    this.getProductList()
    this.getTypeOptions()
  },
  methods: {
    getProductList() {
      this.dataLoding = true
      this.$axios
        .post(this.$api.productList, {
          page: this.currentPage,
          pageSize: this.pageSize,
          auditStatus: 1,
          serialId: this.serialId || null,
          productName: this.productName || null,
          // tendersStatus: this.tendersStatus || null,
          barCode: this.codeValue || null, // 商品条形码
          categoryIdLists: this.categoryIds.length == 0 ? null : this.categoryIds,
          // selectType: 1,
        })
        .then(res => {
          if (res.data.code == 100) {
            this.dataLoding = false
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
          }
        })
    },
    getTypeOptions() {
      this.$axios
        .get(this.$api.categoryList, {
          params: {
            auditStatus: 1,
            page: 1,
            pageSize: 9999,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            let list = res.data.result.list
            list.forEach(t => {
              if (t.children.length == 0) t.children = null
            })
            this.typeOptions = list
          }
        })
    },
    changetype() {
      this.tableData = []
      this.currentPage = 1
      this.getProductList()
    },
    goToEdit(row) {
      this.$router.push({ path: "/productMgt/detail/edit", query: { productId: row.productId } })
    },
    handleOpenPicDialog(row) {
      this.picDialogVisible = true
      this.$set(this.picRuleForm, "productName", row.productName)
      this.$set(this.picRuleForm, "productId", row.productId)
      if (row.picUrlList && Array.isArray(row.picUrlList)) {
        this.$set(this.picRuleForm, "picUrl1", row.picUrlList[0] || "")
        this.$set(this.picRuleForm, "picUrl2", row.picUrlList[1] || "")
        this.$set(this.picRuleForm, "picUrl3", row.picUrlList[2] || "")
      } else {
        this.$set(this.picRuleForm, "picUrl1", "")
        this.$set(this.picRuleForm, "picUrl2", "")
        this.$set(this.picRuleForm, "picUrl3", "")
      }
    },
    // 关闭对话框
    onCancelPicDialog() {
      this.picDialogVisible = false
      this.resetFormPic()
    },
    // 提交表单
    submitFormPic(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .post(this.$api.updateProductPic, {
              productId: this.picRuleForm.productId,
              picUrlList: [this.picRuleForm.picUrl1 || "", this.picRuleForm.picUrl2 || "", this.picRuleForm.picUrl3 || ""],
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc)
                this.getProductList()
                this.onCancelPicDialog()
              }
            })
            .catch(err => {
              this.$message.error(err.data.desc)
            })
        }
      })
    },
    // 重置校验
    resetFormPic() {
      this.$refs.picRuleForm.resetFields()
      this.picRuleForm = {}
    },
    handleRemoveLogo1() {
      this.picRuleForm.picUrl1 = ""
    },
    handleSuccess1(res) {
      if (res.code == 100) {
        this.picRuleForm.picUrl1 = res.result.fileUrl
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        })
      }
    },
    handleRemoveLogo2() {
      this.picRuleForm.picUrl2 = ""
    },
    handleSuccess2(res) {
      if (res.code == 100) {
        this.picRuleForm.picUrl2 = res.result.fileUrl
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        })
      }
    },
    handleRemoveLogo3() {
      this.picRuleForm.picUrl3 = ""
    },
    handleSuccess3(res) {
      if (res.code == 100) {
        this.picRuleForm.picUrl3 = res.result.fileUrl
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        })
      }
    },
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },

    handleInputNumAndEng() {
      // 使用正则表达式验证输入是否为英文字符和数字
      this.ruleForm.barCode = this.ruleForm.barCode.replace(/[^a-zA-Z0-9]/g, "")
    },
    // 新增（对话框）
    handleAdd() {
      // this.isAdd = true
      // this.dialogVisible = true
      // this.ruleForm = {}
      this.$router.push("/productMgt/detail/apply")
    },
    handleEdit(row) {
      console.log(row)
      this.isAdd = false
      let { productSupplierId, categoryId, productName, specsValue, specsUnit, brand, factoryName, barCode, enterBox, validityPeriod } = row
      this.ruleForm = {
        productSupplierId,
        categoryId,
        productName,
        specsValue,
        specsUnit,
        brand,
        factoryName,
        barCode,
        enterBox,
        validityPeriod,
      }
      this.dialogVisible = true
    },
    // 用于检查是否为正整数
    isSpecsValueValid(value) {
      return /^\d+$/.test(value)
    },
    submitForm(formName) {
      // 手动检查specsValue的值
      const specsValue = this.ruleForm.specsValue
      let skipSpecsValueValidation = false
      if (specsValue && this.isSpecsValueValid(specsValue)) {
        skipSpecsValueValidation = true
      }

      this.$refs[formName].validate(valid => {
        if (!valid && !skipSpecsValueValidation) return console.error("表单必填项")
        this.submitLoding = true
        let params = Object.assign({}, this.ruleForm)
        // console.log('传值', params);
        let url = params.productSupplierId ? "productPut" : "productAdd"
        let msg = params.productSupplierId ? "修改" : "新增"
        let method = params.productSupplierId ? "put" : "post"
        this.$axios[method](this.$api[url], params)
          .then(res => {
            this.submitLoding = false
            if (res.data.code == 100) {
              this.$message.success(`${msg}成功`)
              if (params.productSupplierId) {
                this.tableData = this.tableData.filter(t => t.productSupplierId != params.productSupplierId)
              }
              this.onCancel()
              this.getProductList()
            }
          })
          .catch(() => {
            this.submitLoding = false
          })
      })
    },
    onCancel() {
      this.$refs["ruleForm"].resetFields()
      this.ruleForm = {}
      this.dialogVisible = false
      this.isAdd = false
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.onScreenData()
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      this.getProductList()
    },
    // 筛选
    onScreenData() {
      this.currentPage = 1
      this.tableData = []
      this.getProductList()
    },
    // 删除
    handleDelete(row) {
      this.$confirm("确定删除该商品？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          return this.$axios.put(this.$api.productDelete, {
            productSupplierId: row.productSupplierId,
          })
        })
        .then(res => {
          if (res.data.code == 100) {
            this.$message.success("删除成功")
            this.tableData = this.tableData.filter(t => t.productSupplierId != row.productSupplierId)
          } else {
            this.$message.error("删除失败")
          }
        })
    },
    // 模板下载
    downloadTemplate() {
      const link = document.createElement("a")
      link.style.display = "none"
      link.href = "https://xn--fcs331b8yd3xj5in.com/files/default/商品批量申请模版.xlsx"
      link.download = "批量申请模版.xlsx"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // 导入弹窗关闭
    onImportCancel() {
      this.importShow = false
      this.importFiles = []
    },
    beforeUpload(file) {
      console.log(file)
      const suffixArr = ["xlsx", "xls"]
      const maxSize = 1024 * 1024 * 5
      if (!suffixArr.includes(file.name.split(".").pop())) {
        this.$message.error(`只能上传"xlsx"、"xls"文件`)
        this.importFiles = []
        return false
      }
      if (file.size > maxSize) {
        this.$message.error("附件不能超过5M")
        this.importFiles = []
        return false
      }
      this.importFiles.push(file.raw)
    },
    handleExceed() {
      this.$message.warning(`只能上传一个文件！`)
    },
    handleRemove() {
      this.importFiles = []
    },
    // 导入表单校验
    submitFormImport() {
      if (!this.importFiles || this.importFiles.length == 0) {
        return this.$message.warning("请先上传文件")
      }
      let parmas = new FormData()
      parmas.append("file", this.importFiles[0])
      parmas.append("type", 3)
      this.$axios.post(this.$api.importNewProductApply, parmas).then(res => {
        if (res.data.code == 100) {
          this.$message.success("导入成功!")
          this.onImportCancel()
        }
      })
    },
    // 图片预览
    previewImage(url) {
      this.imgList = url
      this.showViewer = true
    },
  },
}
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  .el-button,
  .input-box {
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .types {
    ::v-deep .el-cascader {
      width: 280px;

      .el-input,
      .el-input__inner {
        width: 280px;
      }
    }

    ::v-deep .el-cascader__tags .el-tag {
      background-color: #fff;
    }
  }
}

.show_img {
  color: #c27c88;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.el-upload__tip {
  color: #eb2500;
}

// 图片
.logo_pic {
  position: relative;

  ::v-deep .el-form-item__content {
    width: 100px;
    height: 100px;
  }

  .pic-row {
    display: flex;

    .pic {
      margin-right: 15px;

      .logo_uploader {
        ::v-deep .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        ::v-deep .el-upload:hover {
          border-color: #c27c88;
        }

        .avatar-uploader-icon {
          font-size: 28px;
          color: #c27c88;
          width: 100px;
          height: 100px;
          line-height: 100px;
          text-align: center;
        }

        .logoimg {
          width: 100px;
          height: 100px;
          display: block;
          object-fit: cover;
        }
      }
    }
  }

  .delpic {
    position: absolute;
    top: -12px;
    color: red;
    font-size: 20px;
    cursor: pointer;
  }

  .delpic1 {
    right: -15px;
  }

  .delpic2 {
    right: -134px;
  }

  .delpic3 {
    right: -250px;
  }
}

.day {

  ::v-deep .el-input,
  ::v-deep .el-input__inner {
    width: 205px;
  }

  ::v-deep .el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }

  ::v-deep .el-input__inner {
    background: #f2f2f2;
    border-radius: 10px 0 0 10px;
    border: none;
  }

  ::v-deep .el-input-group__append {
    background: #f2f2f2;
    border-radius: 0px 10px 10px 0;
    border: none;
    color: #000;
  }
}

/* input number类型取消增减箭头*/
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// 光标上移
::v-deep .el-input__inner {
  line-height: 1px !important;
}
</style>
